import {children} from "solid-js";

export const AddMultiple = (props) => {
    const onAddItem = async () => {
    }

    return (
        <>
            <button className='btn btn-sm btn-primary' type='button' onClick={onAddItem} title={props.title}>
                <i className={`fa-light fa-plus me-2`} /> {props.title}
            </button>
        </>
    )
}