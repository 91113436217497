import {createSignal, Show} from 'solid-js';
import i18n from './translations';

export const CompanyForm = (props) => {
    const [isCompany, setIsCompany] = createSignal(props.isCompany)

    return (
        <>
            <div className="form-check mb-3">
                <input name={`${props.scope}[is_company]`} type="hidden" value="0" autoComplete="off" />
                <input className="form-check-input" type="checkbox" value="1" name={`${props.scope}[is_company]`} checked={isCompany()} onChange={() => setIsCompany(!isCompany())} id={`${props.scope}_is_company`} />
                <label className="form-check-label" htmlFor={`${props.scope}_is_company`}>{i18n.t('customer.is_company')}</label>
            </div>

            <Show when={isCompany()}>
                <div className="form-group">
                    <label className="form-label required" htmlFor={`${props.scope}_company_name`}>{i18n.t('customer.company_name')}</label>
                    <input required="required" className="form-control" name={`${props.scope}[company_name]`} value={props.companyName} id={`${props.scope}_company_name`} />
                </div>
            </Show>
        </>
    )
}