import {onCleanup, onMount} from 'solid-js';

export const ScrollingSentinel = (props) => {
    let sentinel
    let isLocked = false

    const onIntersection = (entries) => {
        entries.forEach(async entry => {
            if(!entry.isIntersecting || isLocked) { return }

            isLocked = true

            const url = new URL(location.href)
            url.searchParams.set('page', props.page)

            const response = await fetch(url, {
                headers: {
                    'Accept': 'application/javascript',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })

            if(response.status === 200) {
                const articles = document.querySelector(props.target)
                articles.insertAdjacentHTML('beforeend', await response.text())
            }
        })
    }

    const observer = new IntersectionObserver(onIntersection)

    onMount(() => {
        observer.observe(sentinel)
    })

    onCleanup(() => {
        observer.unobserve(sentinel)
    })

    return (
        <div ref={sentinel} className='sentinel'></div>
    )

}