import {createResource, For, onCleanup, onMount, Show} from 'solid-js';
import {get, post, patch, destroy} from './api';
import i18n from './translations';

const dispatchEvent = (name, detail) => {
    const event = new CustomEvent(name, {detail})
    window.dispatchEvent(event);
}

export const CartToast = () => {
    let dialog;

    onMount(() => {
        window.addEventListener('cart:add-item', showDialog, false)
        dialog.addEventListener('click', onClick, false)
    })

    onCleanup(() => {
        window.removeEventListener('cart:add-item', showDialog, false)
        dialog.removeEventListener('click', onClick, false)
    })

    const onClick = ({target}) => {
        if(target === dialog) {
            dialog.close()
        }
    }

    const onClose = () => {
        dialog.close()
    }

    const showDialog = () => {
        dialog.showModal()
    }

    return (
        <dialog ref={dialog}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{i18n.t('cart.title')}</h5>
                    <button type="button" className="btn-close shadow-none" aria-label="Close" onClick={onClose}></button>
                </div>
                <div className="modal-body my-4">
                    <p>{i18n.t('cart.toast.message')}</p>
                </div>
                <div className="modal-footer mt-2">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" onClick={onClose}>{i18n.t('cart.toast.continue_shopping')}</button>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <a href="/cart" className="btn btn-outline-primary">{i18n.t('cart.toast.view_cart')}</a>
                    </div>
                </div>
            </div>
        </dialog>
    )
}

export const CartLink = (props) => {

    const [items, {refetch}] = createResource(async () => {
        const response = await get('cart/items')

        if(response.status !== 200) {
            return 0
        }

        return parseInt(await response.text(), 10)
    })

    onMount(() => {
        window.addEventListener('cart:update', refetch, false)
        window.addEventListener('cart:add-item', refetch, false)
    })

    onCleanup(() => {
        window.removeEventListener('cart:update', refetch, false)
        window.removeEventListener('cart:add-item', refetch, false)
    })

    return (
        <Show when={items() > 0} keyed={true}>
            <a className='btn btn-primary position-relative' href={props.href}>
                <i className='fa-light fa-cart-shopping' />
                    <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>{items()}</span>
            </a>
        </Show>
    )
}

export const CartButton = (props) => {
    const onAddItem = async () => {
        const response = await post('cart', {articleId: props.articleId})

        if(response.status === 200) {
            dispatchEvent('cart:add-item')
        }
    }

    return (
        <button className='btn btn-sm btn-primary' type='button' onClick={onAddItem} title={i18n.t('cart.add')}>
            <i className={`fa-light fa-${props.icon} me-2`} /> {i18n.t('cart.add')}
        </button>
    )
}

export const Cart = () => {

    const cartFetcher = async () => {
        const response = await get('cart')

        if(response.status !== 200) {
            return {}
        }

        const result = await response.json()

        dispatchEvent('cart:update')

        if(result.items.length === 0) {
            return result
        }

        return result
    }

    const [cart, { refetch }] = createResource(cartFetcher)

    onMount(() => {
        window.addEventListener('cart:add-item', refetch, false)
    })

    onCleanup(() => {
        window.removeEventListener('cart:add-item', refetch, false)
    })

    const formatPrice = ({cents, currency_iso}) => {
        return new Intl.NumberFormat(document.documentElement.lang, { style: 'currency', currency: currency_iso }).format(cents / 100.0)
    }

    const onTrash = async (articleId) => {
        const response = await destroy('cart/items', articleId)

        if(response.status === 200) {
            refetch()
        }
    }

    const onUpdate = async ({articleId, operation}) => {
        const response = await patch('cart/items', articleId, {operation: operation})

        if(response.status === 200) {
            refetch()
        }
    }

    const cartEmpty = <div className='alert alert-primary' role='alert'>{i18n.t('cart.messages.empty')}</div>

    return (
        <Show when={cart() && cart().items.length > 0} fallback={cartEmpty}>
            <div className='row'>
                <div className='col-12 col-lg-6'>
                    <ul className=' m-0 p-0 cart-list'>
                        <For each={cart().items}>{(item, i) =>
                            <li className='d-flex px-0 py-6 justify-content-between align-items-start cart-row'>
                                <div className='d-flex align-items-start flex-column cart-details'>
                                    <h2 className='cart-article-name'>
                                        {item.name}
                                    </h2>
                                    <div className='cart-article-properties'>
                                        Art. Nr.: {item.articleId}
                                    </div>

                                    <div className='cart-controls'>
                                        <div className='input-group'>
                                            <button className='btn btn-secondary btn-decrease' type='button' title={i18n.t('cart.actions.decrease')} onClick={[onUpdate, {articleId: item.articleId, operation: 'decrement'}]}>
                                                <i className='fa-light fa-minus' />
                                            </button>
                                            <input type='text' className='form-control' disabled='true' value={item.quantity} />
                                            <button className='btn btn-secondary btn-increase' type='button' title={i18n.t('cart.actions.increase')} onClick={[onUpdate,  {articleId: item.articleId, operation: 'increment'}]}>
                                                <i className='fa-light fa-plus' />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-column align-items-end'>
                                    <button className='btn btn-sm btn-danger mb-3' type='button' title={i18n.t('cart.actions.delete')} onClick={[onTrash, item.articleId]}>
                                        <i className='fa-light fa-trash' />
                                    </button>
                                    <div className='cart-price text-muted my-1'>
                                        Einzelpreis {formatPrice(item.unitPrice)}
                                    </div>
                                    <div className='cart-price my-1'>
                                        Gesamt {formatPrice(item.amount)}
                                    </div>
                                </div>
                            </li>
                        }</For>
                    </ul>
                </div>
                <div className='col-12 offset-lg-1 col-lg-5'>
                    <div className='card card-summary'>
                        <div className='card-header'>
                            <h4>{i18n.t('cart.order.summary')}</h4>
                        </div>
                        <div className='card-body pt-0'>
                            <Show when={cart().total}>
                                <ul>
                                    <li>
                                        <div className='text-muted'>{i18n.t('cart.order.total_net')}</div>
                                        <div>{formatPrice(cart().totalNet)}</div>
                                    </li>
                                    <li>
                                        <div className='text-muted'>{i18n.t('cart.order.tax')} ({cart().taxRate}%)</div>
                                        <div>{formatPrice(cart().tax)}</div>
                                    </li>
                                    <li>
                                        <div className='fw-bold'>{i18n.t('cart.order.total')}</div>
                                        <div className='fw-bold'>{formatPrice(cart().total)}</div>
                                    </li>
                                </ul>
                            </Show>
                            <a href='/checkout' className='btn btn-primary flex-grow-1 btn-lg'>
                                {i18n.t('cart.checkout')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Show>
    )
}