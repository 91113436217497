import { I18n } from 'i18n-js';

const en = {
    cart: {
        title: 'Cart',
        checkout: 'Checkout',
        add: 'Add to cart',
        order: {
            summary: 'Order summary',
            total_net: 'Subtotal',
            tax: 'Taxes',
            total: 'Order total'
        },
        toast: {
            continue_shopping: 'Continue shopping',
            view_cart: 'View cart',
            message: 'The selected item has been added to your cart'
        },
        messages: {
            empty: 'Your shopping cart contains no items'
        },
        actions: {
            delete: 'Delete',
            increase: 'Increase',
            decrease: 'Decrease'
        }
    },
    choices: {
        kind: {
            single: 'Single-selection',
            multiple: 'Multi-selection'
        },
        move: 'Move answer',
        correct: 'Correct choice',
        add_choice: 'Add answer'
    },
    customer: {
        is_company: 'Company',
        company_name: 'Company name'
    },
    placeholders: {
        search_term: 'Search term'
    },
    exams: {
        time_remaining: 'Time remaining',
        submit: 'Submit',
        confirm_submission: 'Do you really want to hand in the exam?'
    },
    avatar_editor: {
        title: 'Update profile picture',
        save: 'Save',
        close: 'Close',
        zoom_in: 'Zoom in',
        zoom_out: 'Zoom out',
        alerts: {
            too_small: 'The selected image is too small. The minimum size is 300x300 pixel.'
        }
    }
}

const de = {
    cart: {
        title: 'Warenkorb',
        checkout: 'Zur Kasse',
        add: 'In den Warenkorb',
        order: {
            summary: 'Warenkorbübersicht',
            total_net: 'Zwischensumme',
            tax: 'Steuer',
            total: 'Gesamtsumme'
        },
        toast: {
            continue_shopping: 'Weiter einkaufen',
            view_cart: 'Zum Warenkorb',
            message: 'Der ausgewählte Artikel wurde Ihrem Warenkorb hinzugefügt'
        },
        messages: {
            empty: 'Ihr Warenkorb enthält keine Artikel'
        },
        actions: {
            delete: 'Löschen',
            increase: 'Erhöhen',
            decrease: 'Verringern'
        }
    },
    choices: {
        kind: {
            single: 'Einfachauswahl',
            multiple: 'Mehrfachauswahl'
        },
        move: 'Antwort verschieben',
        correct: 'Korrekte Antwortmöglichkeit',
        add_choice: 'Antwort hinzufügen'
    },
    customer: {
        is_company: 'Firma',
        company_name: 'Firmenname'
    },
    placeholders: {
        search_term: 'Suchbegriff'
    },
    exams: {
        time_remaining: 'Verbleibende Zeit',
        submit: 'Abgeben',
        confirm_submission: 'Die Prüfung wirklich einreichen?'
    },
    avatar_editor: {
        title: 'Profilbild aktualisieren',
        save: 'Speichern',
        close: 'Schließen',
        zoom_in: 'Ausschnitt vergrößern',
        zoom_out: 'Ausschnitt verkleinern',
        alerts: {
            too_small: 'Das gewählte Bild ist zu klein. Die Mindestgröße ist 300x300 pixel.'
        }
    }
}

const i18n = new I18n({en, de})
i18n.locale = document.documentElement.lang;

export default i18n