import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import {processKeys} from '../components/api';
const initSortables = () => {

    const onUpdate = async ({item, target, newIndex}) => {
        const body = JSON.stringify({
            index: newIndex + 1
        }, processKeys)

        await fetch(`${target.dataset.sortable}/${item.dataset.id}/move`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            method: 'POST',
            body
        })
    }

    const options = {
        draggable: '[data-id]',
        direction: 'vertical',
        animation: 150,
        ghostClass: 'sortable-placeholder',
        onUpdate
    }

    const sortables = document.querySelectorAll('[data-sortable]')

    for(const sortable of sortables) {
        Sortable.create(sortable, options)
    }

}

export default initSortables()

