import i18n from './translations';
import {debounce} from '@solid-primitives/scheduled';
import {refreshArticles} from './shared';

export const SearchField = () => {

    const trigger = debounce(async (value) => {

        const url = new URL(window.location)

        url.searchParams.delete('page')

        if(value === '') {
            url.searchParams.delete('query')
        } else {
            url.searchParams.set('query', value)
        }

        window.history.pushState({}, '', url)

        await refreshArticles()

    }, 300)

    const query = () => new URL(window.location).searchParams.get('query')

    return (
        <div className='mb-3'>
            <input type='text'
                   value={query()}
                   className='form-control'
                   placeholder={i18n.t('placeholders.search_term')}
                   onInput={e => {
                       trigger.clear()
                       trigger(e.target.value)
                   }}
                   autoFocus={true}
            />
        </div>
    )
}