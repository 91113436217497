import * as pdf from 'pdfjs-dist/webpack';
import {createMemo, createResource, createSignal, onMount, Show} from 'solid-js';

export const PdfViewer = (props) => {
    let container
    let canvas
    let document

    const [pages, setPages] = createSignal(0)
    const [currentPage, setCurrentPage] = createSignal(0)
    const [canvasSize, setCanvasSize] = createSignal({ width: 0, height: 0 });

    onMount(async () => {
        document = await pdf.getDocument(props.url).promise;

        setPages(document.numPages)

        openPage(1)
    })

    const renderPage = async (pageNumber) => {
        const page = await document.getPage(pageNumber)

        let viewport = page.getViewport({scale: 1.0})
        viewport = page.getViewport({scale: container.clientWidth / viewport.width})

        const {width, height} = viewport

        setCanvasSize({width, height});

        const task = page.render({viewport, canvasContext: canvas.getContext('2d')})

        return task.promise
    }

    const [renderTask] = createResource(currentPage, renderPage);

    const pageRendering = createMemo(() => {
        return renderTask.loading;
    });

    const openPage = (pageNumber) => {
        if(pageRendering()) { return }

        setCurrentPage(pageNumber)
    }

    return (
        <div ref={container}>
            <canvas ref={canvas} {...canvasSize()} />
            <Show when={pages() > 1}>
                <div class='pdf-controls d-flex justify-content-between p-2'>
                    <button class='btn btn-primary' type='button' disabled={currentPage() === 1} onClick={() => openPage(currentPage() - 1)}>
                        <i class='fal fa-left'/>
                    </button>
                    <button class='btn btn-primary' type='button' disabled={currentPage() === pages()} onClick={() => openPage(currentPage() + 1)}>
                        <i class='fal fa-right'/>
                    </button>
                </div>
            </Show>
        </div>
    )
}