import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {onMount} from 'solid-js';

export const Payment = (props) => {
    let paymentContainer

    const session = { id: props.sessionId }

    if(props.sessionData) {
        session['sessionData'] = props.sessionData
    }

    const configuration = {
        environment: props.environment,
        clientKey: props.clientKey,
        analytics: { enabled: false },
        session,
        onPaymentCompleted: ({resultCode}, component) => {
            const successCodes = ['Received', 'Pending', 'Authorised']

            if(successCodes.includes(resultCode)) {
                location.replace(`/account/orders/${props.order}`)
            }
        },
        onError: ({name, message}) => {
            console.error(name, message)
        }
    };

    onMount(async () => {

        const checkout = await AdyenCheckout(configuration);

        if(props.result) {
            checkout.submitDetails({details: {redirectResult: props.result}})
        }

        checkout.create('dropin').mount(paymentContainer);

    })

    return (
        <div ref={paymentContainer} />
    )

}