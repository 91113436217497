const initCopyLink = () => {

    const links = document.querySelectorAll('a[data-copy-link="to-clipboard"]')

    for(const link of links) {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            let input = document.createElement('input');
            input.setAttribute('type', 'text');
            input.setAttribute('value', this.href)

            this.parentElement.appendChild(input);
            input.select();

            document.execCommand('copy');
            this.parentElement.removeChild(input);

            alert('Link wurde in die Zwischenablage kopiert.')
        });
    }

}

export default initCopyLink()