import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css';
import '@fortawesome/fontawesome-pro/css/thin.css';
import '@fortawesome/fontawesome-pro/css/brands.css';
import Rails from '@rails/ujs';

import 'scripts/navbar_top';
import 'scripts/sortable';
import 'scripts/tooltip';
import 'scripts/copy_to_clipboard';

// Register frontend components

import {Cart, CartButton, CartLink, CartToast} from './components/cart';
import {AddMultiple} from './components/add-multiple';
import {SearchField} from './components/search-field';
import {FilterItem} from './components/filter-item';
import {registerComponent} from './components/registry';
import {Payment} from './components/payment';
import {Exam} from './components/exam';
import {CompanyForm} from './components/company_form';
import {PdfViewer} from './components/pdf-viewer';
import {Confetti} from './components/confetti';
import {ScrollingSentinel} from './components/scrolling-sentinel';

registerComponent('sciodo-cart', {}, Cart)
registerComponent('sciodo-cart-button', {icon: 'cart-plus', articleId: ''}, CartButton)
registerComponent('sciodo-cart-link', {href: ''}, CartLink)
registerComponent('sciodo-cart-toast', {}, CartToast)
registerComponent('sciodo-add-multiple', {title: ''}, AddMultiple)
registerComponent('sciodo-search-field', {}, SearchField)
registerComponent('sciodo-filter-item', {key: '', label: '', value: '', active: false}, FilterItem)
registerComponent('sciodo-payment', {environment: '', order: '', sessionId: '', sessionData: '', clientKey: '', result: ''}, Payment)
registerComponent('sciodo-exam', {identifier: ''}, Exam)
registerComponent('sciodo-company-form', {scope: 'registration', isCompany: false, companyName: ''}, CompanyForm)
registerComponent('sciodo-pdf-viewer', {url: ''}, PdfViewer)
registerComponent('sciodo-confetti', {}, Confetti)
registerComponent('sciodo-scrolling-sentinel', {page: 1, target: ''}, ScrollingSentinel)

Rails.start()