const apiRequest = async (method, path, body) => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.sciodo+json; version=1'
    }

    if(body) {
        body = JSON.stringify(body, processKeys)
    }

    return fetch(`/api/${path}`, {
        method, body, headers
    })
}

const underscore = value => value.replace( /([A-Z])/g, " $1" ).split(' ').join('_').toLowerCase()

export const processKeys = (_key, value) => {
    if(!value || typeof value !== 'object' || Array.isArray(value)) { return value }

    const newValue = {}

    for(const key in value) {
        newValue[underscore(key)] = value[key]
        delete value[key]
    }

    return newValue
}

export const post = async (url, body) => {
    return apiRequest('post', url, body)
}

export const get = async (url) => {
    return apiRequest('get', url)
}

export const patch = async (url, recordId, body) => {
    return apiRequest('PATCH', `${url}/${recordId}`, body)
}

export const destroy = async (url, recordId) => {
    return apiRequest('delete', `${url}/${recordId}`)
}