import {createSignal} from 'solid-js';
import {refreshArticles} from './shared';

export const FilterItem = (props) => {
    const key = `${props.key}[]`
    const [active, setActive] = createSignal(props.active)

    const applyFilter = async (value) => {
        const url = new URL(window.location)
        const items = url.searchParams.getAll(key)

        url.searchParams.delete('page')

        if(!items.includes(value)) {
            url.searchParams.append(key, value)
        } else {
            url.searchParams.delete(key)

            items.filter(t => t !== value).forEach(t => url.searchParams.append(key, t))
        }

        setActive(!active())

        window.history.pushState({}, '', url)

        await refreshArticles()
    }

    return (
        <div className='filter-element' classList={{'active': active()}} onClick={[applyFilter, props.value]}>
            <span>{props.label}</span>
            <i className={`fa-light ${active() ? 'fa-check-square' : 'fa-square'}`}/>
        </div>
    )
}