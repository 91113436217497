export const refreshArticles = async () => {
    const result = await fetch(location.href, {
        headers: {
            'Accept': 'application/javascript',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })

    if(result.status === 200) {
        const content = await result.text()

        const parent = document.getElementById('articles')
        parent.innerHTML = content
    }
}