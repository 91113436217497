import i18n from './translations';
import {createResource, createSignal, For, Show} from 'solid-js';
import {get, post} from './api';
import {createStore} from "solid-js/store";

const examFetcher = async (identifier) => {
    const response = await get(`exams/${identifier}`)

    if(response.status === 200) {
        return await response.json()
    } else {
        return null
    }
}

const SavingIndicator = (props) => (
    <Show when={props.status}>
        <i className={`fa-light fa-floppy-disk text-${props.status === 'failure' ? 'danger' : props.status}`} />
    </Show>
)

const Question = (props) => {
    const [savingStatus, setSavingStatus] = createSignal(null)
    const [choices, setChoices] = createStore(props.choices)

    const toggleSavingStatus = (success) => {
        const status = success ? 'success' : 'failure'

        setSavingStatus(status)

        setTimeout(() => setSavingStatus(null), 500)
    }

    const updateSelection = (value) => {
        if(props.kind === 'single') {
            return choices.reduce((acc, c) => [...acc, Object.assign({}, c, {selected: isSameId(c.id, value) && !c.selected})], [])
        } else {
            return choices.map(c => isSameId(c.id, value) ? Object.assign({}, c, {selected: !c.selected}) : c)
        }
    }

    const saveSelection = async (newChoices) => {

        try {

            const response = await post(`exams/${props.examIdentifier}/answer`, {
                questionId: props.id, choices: newChoices.filter(c => c.selected).map(c => c.id)
            })

            if(response.status === 200) {
                const selection = await response.json()
                setChoices(choices.map(c => Object.assign({}, c, {selected: selection.includes(c.id)})))

                return true
            }

        } catch {
            // Catch network errors to allow indicating failure to save to user
        }

        return false

    }

    const isSameId = (id, value) => id === parseInt(value, 10)

    const onClickChoice = async (e) => {
        const {target: {value}} = e

        e.preventDefault()

        const newChoices = updateSelection(value)
        const success = await saveSelection(newChoices)

        toggleSavingStatus(success)
    }

    return (
        <div className='card question'>
            <div className='card-header'>
                <h4 className='card-header-title'>
                    { props.title }
                </h4>
                <Show when={props.kind === 'multiple'}>
                    <div className='card-subtitle'>(Mehrfachauswahl möglich)</div>
                </Show>
                <div className='saving-indicator'>
                    <SavingIndicator status={savingStatus()} />
                    <Show when={props.description}>
                        <a href='#' data-bs-toggle='tooltip' data-bs-title={props.description}>
                            <i className='fa-light fa-question-circle'/>
                        </a>
                    </Show>
                </div>
            </div>
            <div className='card-body'>
                <div className='checklist'>
                    <For each={choices}>
                        {(choice) =>
                            <div className='form-check'>
                                <input className='form-check-input' name={`question-${props.id}`} value={choice.id} id={`choice-${choice.id}`} type={props.kind === 'single' ? 'radio' : 'checkbox'} checked={choice.selected} onClick={onClickChoice} />
                                <label className='form-check-label' htmlFor={`choice-${choice.id}`}>{choice.value}</label>
                            </div>
                        }
                    </For>
                </div>
            </div>
        </div>
    )
}

const Timer = (props) => {
    const [timeRemaining, setTimeRemaining] = createSignal(props.timeRemaining)

    const hours = () => Math.floor(timeRemaining() / 3600)
    const minutes = () => Math.floor(timeRemaining() / 60) - hours() * 60
    const seconds = () => timeRemaining() - minutes() * 60 - hours() * 3600;

    let expectedTime = Date.now() + 1000

    const step = () => {

        if(timeRemaining() === 0) {
            props.onExpire()
            return;
        }

        const drift = Date.now() - expectedTime;

        setTimeRemaining(previous => previous - 1)

        expectedTime += 1000;

        setTimeout(step, Math.max(0, 1000 - drift));

    }

    setTimeout(step, 1000)

    return (
        <div className='timer'>
            <Show when={hours() > 0}>
                <span className='minutes'>{hours().toString().padStart(2, '0')}</span>
                <span className='separator'>:</span>
            </Show>
            <span className='minutes'>{minutes().toString().padStart(2, '0')}</span>
            <span className='separator'>:</span>
            <span className='minutes'>{seconds().toString().padStart(2, '0')}</span>
        </div>
    )
}

export const Exam = (props) => {
    const [isSaving, setIsSaving] = createSignal(false)

    const [exam] = createResource(props.identifier, examFetcher)

    const onExpire = () => {
        location.replace(`/exams/${props.identifier}/result`)
    }

    const onSubmit = () => {
        if(!confirm(i18n.t('exams.confirm_submission'))) { return }

        onExpire()
    }

    return (
        <Show when={exam()}>
            <div className='row'>
                <div className='col-12 col-md order-2 order-md-1'>
                    <div className='questions'>
                            <For each={exam().questions}>
                                {
                                    (question) => <Question examIdentifier={props.identifier} {...question} />
                                }
                            </For>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-success mt-3' onClick={onSubmit}>
                                { !isSaving() ? i18n.t('exams.submit') : i18n.t('exams.submitting') }
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-auto order-1 order-md-2 mb-4 mb-md-0 sidebar'>
                    <div className='timer-container'>
                        <div className='card'>
                            <div className='card-header'>
                                <h4 className='title'>{ i18n.t('exams.time_remaining') }</h4>
                            </div>
                            <div className='card-body'>
                                <Timer timeRemaining={exam().timeRemaining} onExpire={onExpire} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Show>
    )

}