document.addEventListener('DOMContentLoaded', function(){

    const navbar = document.getElementById('navbar_top')

    if(!navbar) {
        return
    }

    window.addEventListener('scroll', function() {

        if (window.scrollY > 150) {
            navbar.classList.add('fixed-top', 'shadow-sm');
            // add padding top to show content behind navbar
            const navbar_height = document.querySelector('.navbar').offsetHeight;
            //document.body.style.paddingTop = navbar_height + 'px';
        } else {
            navbar.classList.remove('fixed-top', 'shadow-sm');
            // remove padding top from body
            //document.body.style.paddingTop = '0';
        }
    });
}); 