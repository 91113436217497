import {compose, register} from 'component-register';
import {withSolid} from 'solid-element';

const withoutShadowDOM = (ComponentType) => {
    return (props, options) => {
        const { element } = options;
        Object.defineProperty(element, 'renderRoot', {
            value: element
        });
        return ComponentType(props, options);
    }
}

export const registerComponent = (tag, props, component) => {
    compose(register(tag, props), withoutShadowDOM, withSolid)(component)
}